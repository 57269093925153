import React from 'react'
import PrivacyPolicy from '../components/PrivacyPolicy'
import SEO from "../components/Layout/SEO"
import Layout from "../components/Layout"





const DisclaimerPage = () => (
  <Layout seoProps={{title: "Disclaimer"}}>
    <PrivacyPolicy />
  </Layout>
)

export default DisclaimerPage